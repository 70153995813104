import { render, staticRenderFns } from "./ConferencePapers.vue?vue&type=template&id=64fea769&scoped=true&"
import script from "./ConferencePapers.vue?vue&type=script&lang=js&"
export * from "./ConferencePapers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64fea769",
  null
  
)

export default component.exports