<template>
  <div class="utility-model-page">
    <el-form ref="addForm" label-width="170px" :rules="rules" :model="addForm">
      <el-row type="flex" class="row-bg" justify="end">
        <el-col :span="11">
          <el-form-item label="论文名称:" prop="ArchivesName">
            <el-input type="textarea" v-model="addForm.ArchivesName"></el-input>
          </el-form-item>
          <el-form-item label="发表日期:" prop="IssuingDate">
            <el-date-picker
              v-model="addForm.IssuingDate"
              type="month"
              placeholder="请选入年份+月份"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="作者(1-6):" prop="ArchivesUser">
            <el-input v-model="addForm.ArchivesUser" placeholder="如果多个作者请用英文逗号隔开"></el-input>
          </el-form-item>
          <el-form-item label="检索号:" prop="ArchivesCode">
            <el-input v-model="addForm.ArchivesCode"></el-input>
          </el-form-item>
          <el-form-item label="期刊名称:" prop="JournalArticle">
            <el-input  v-model="addForm.JournalArticle"></el-input>
          </el-form-item>
          <el-form-item label="期刊影响因子:" prop="Factor">
            <el-input v-model="addForm.Factor"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="1">
        </el-col>
        <el-col :span="11">
          <el-form-item label="检索源:" prop="Searching">
            <!--        <el-input v-model="addForm.Searching"></el-input>-->
            <el-select v-model="addForm.Searching" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科门类:" prop="Category">
            <el-input v-model="addForm.Category"></el-input>
          </el-form-item>
          <el-form-item label="出版单位:" prop="Publisher">
            <el-input v-model="addForm.Publisher"></el-input>
          </el-form-item>
          <el-form-item label="是否第一标注:">
            <el-radio v-model="addForm.LabelFlag" :label="1" >是</el-radio>
            <el-radio v-model="addForm.LabelFlag" :label="0">否</el-radio>
          </el-form-item>
          <el-form-item label="所标注项目/基金号:" prop="Source">
            <el-input v-model="addForm.Source"></el-input>
          </el-form-item>
          <el-form-item label="备注:" prop="Remarks">
            <el-input type="textarea" v-model="addForm.Remarks"></el-input>
          </el-form-item>
          <el-form-item label="附件:">
            <el-upload
              class="upload-demo"
              ref="newupload"
              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
              multiple
              action="#"
              :file-list="fileList"
              :auto-upload="false"
              :on-change="handleFileChange"
              :before-remove="handleRemove"
            >
              <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>
            </el-upload>
          </el-form-item>
        </el-col>
        <el-col :span="1"></el-col>
      </el-row>
<!--      <el-form-item label="论文名称:" prop="ArchivesName">-->
<!--        <el-input type="textarea" v-model="addForm.ArchivesName"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="发表日期:" prop="IssuingDate">-->
<!--        <el-date-picker-->
<!--          v-model="addForm.IssuingDate"-->
<!--          type="month"-->
<!--          placeholder="请选入年份+月份"-->
<!--          format="yyyy 年 MM 月"-->
<!--          value-format="yyyy-MM">-->
<!--        </el-date-picker>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="作者(1-6):" prop="ArchivesUser">-->
<!--        <el-input v-model="addForm.ArchivesUser" placeholder="如果多个作者请用英文逗号隔开"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="检索号:" prop="ArchivesCode">-->
<!--        <el-input v-model="addForm.ArchivesCode"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="期刊名称:" prop="JournalArticle">-->
<!--        <el-input  v-model="addForm.JournalArticle"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="范围:">-->
<!--        <el-radio v-model="addForm.Scope" label="全国" >全国</el-radio>-->
<!--        <el-radio v-model="addForm.Scope" label="国际">国际</el-radio>-->
<!--        <el-radio v-model="addForm.Scope" label="地方">地方</el-radio>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="期刊影响因子:" prop="Factor">-->
<!--        <el-input v-model="addForm.Factor"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="检索源:" prop="Searching">-->
<!--        <el-select v-model="addForm.Searching" placeholder="请选择">-->
<!--          <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          >-->
<!--            <span style="float: left">{{ item.label }}</span>-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="学科门类:" prop="Category">-->
<!--        <el-input v-model="addForm.Category"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="出版单位:" prop="Publisher">-->
<!--        <el-input v-model="addForm.Publisher"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="是否第一标注:">-->
<!--        <el-radio v-model="addForm.LabelFlag" :label="1" >是</el-radio>-->
<!--        <el-radio v-model="addForm.LabelFlag" :label="0">否</el-radio>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="所标注项目/基金号:" prop="Source">-->
<!--        <el-input v-model="addForm.Source"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="备注:" prop="Remarks">-->
<!--        <el-input type="textarea" v-model="addForm.Remarks"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="附件:">-->
<!--        <el-upload-->
<!--          class="upload-demo"-->
<!--          ref="newupload"-->
<!--          accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"-->
<!--          multiple-->
<!--          action="#"-->
<!--          :file-list="fileList"-->
<!--          :auto-upload="false"-->
<!--          :on-change="handleFileChange"-->
<!--          :before-remove="handleRemove"-->
<!--        >-->
<!--          <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>-->
<!--        </el-upload>-->
<!--      </el-form-item>-->
    </el-form>
    <div class="btn1">
      <el-button  @click="cancel('addForm')">取消</el-button>
      <el-button  @click="onSumbit('addForm')" :loading="loading">确定</el-button>
    </div>
  </div>
</template>

<script>
import {ruleData} from "@/utils/validationRules"
import { addManagement, TopicArchivesManagementData } from "@/api/fileManagement.js"
import {mapState} from "vuex";
export default {
  data() {
    return {
      loading: false,
      fileList: [],
      addForm: {
      },
      rules: ruleData,
      options: [
        {
          value: "EI来源期刊",
          label: "EI来源期刊"
        },
        {
          value: "SCI来源期刊",
          label: "SCI来源期刊"
        },
        {
          value: "核心期刊",
          label: "核心期刊"
        },
        {
          value: "统计源期刊",
          label: "统计源期刊"
        },
        {
          value: "其他",
          label: "其他"
        },
      ],
    }
  },
  computed: mapState(["projectId"]),
  created() {
    this.addForm = JSON.parse(JSON.stringify(TopicArchivesManagementData))
    this.addForm.Scope = "全国"
    this.addForm.Category = "医学"
    this.addForm.Topicid = this.projectId
    this.addForm.Type = 1
    console.log(this.projectId,"课题id")
  },
  methods: {
    ResetData() {
      // this.addForm = JSON.parse(JSON.stringify(TopicArchivesManagementData))
      console.log(this.addForm,"重置")
    },
    handleRemove(file, fileList) { // 文件选择之后取消
      this.fileList = fileList;
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    cancel(formName) { // 取消
      console.log(this.projectId,"取消")
      this.$refs[formName].resetFields();
      this.$emit("paper-child",false)
    },
    onSumbit(formName) { //上传提交
      console.log(this.addForm,"加载")
      this.loading = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.fileList.forEach(file => {
            fd.append('file',file.raw)
          })
          console.log(this.addForm,"22222");
          fd.append("data", JSON.stringify(this.addForm));
          addManagement(fd).then( res=> {
            if (res.data.Status == 1) {
              this.$message.success(res.data.Message)
              this.loading = false
              this.$refs[formName].resetFields();
              this.$emit("paper-child",true)
            } else {
              this.loading = false
              this.$message.error(res.data.Message)
            }
          })
        } else {
          this.loading = false
          this.$message.error('注意必填项');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
