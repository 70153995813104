<template>
  <div class="common-voucher-page">
    <div class="content_left">
      <treedata @projectId-child="projectIdClick"></treedata>
    </div>
    <div class="common-voucher-content">
      <div class="voucher-header-upload">
        <span>关键字:</span>
        <el-input placeholder="请输入关键字" v-model="Key"></el-input>
        <span>所属单位:</span>
        <el-input placeholder="请输入" v-model="HospitalName"></el-input>
        <span>序号:</span>
        <el-input placeholder="请输入" v-model="SerialNumber"></el-input>
        <span>论文类型:</span>
        <el-select v-model="optionValue" placeholder="请选择" >
          <el-option
            v-for="item in option"
            :key="item.value"
            :label="item.label"
            :value="item.value"
           >
            <span style="float: left">{{ item.label }}</span>
          </el-option>
        </el-select>
        <el-button type="primary" icon="el-icon-search" @click="queryList">查询</el-button>
      </div>
      <div class="file-down-export" style="margin-bottom: 15px">
        <el-button type="primary" icon="el-icon-top" @click="add">上传论文</el-button>
        <el-button type="primary" icon="el-icon-bottom" @click="downFiles">打包下载</el-button>
        <el-button type="primary" icon="el-icon-bottom" @click="exportFiles" :loading="loadingExport">导出表格</el-button>
      </div>
      <div style="display: flex;position: relative">
        <div class="content" style="height: 610px;width: 100%">
          <el-table
            :data="tableData"
            max-height="610"
            :header-cell-style="{
                color: '#333333',
                backgroundColor: '#FBFBFD',
              }"
            border
            fit
            ref="multipleTable"
            :row-key="getRowKey"
            @selection-change="handleSelectionChange"
          >
            <el-table-column
            :reserve-selection="true"
              align="center"
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="序号"
              type="index"
              align="center"
              width="62">
            </el-table-column>
            <el-table-column
              label="论文名称"
              prop="ArchivesName"
              align="center"
              sortable
              min-width="150">
              <template slot-scope="scope">
                <span style="cursor: pointer" @click="seePaper(scope.row)">{{scope.row.ArchivesName}}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="论文类型"
              prop="TypeName"
              align="center"
              sortable
              width="130">
<!--              <template slot-scope="scope">-->
<!--                <span v-show="scope.row.Type == 2">会议论文</span>-->
<!--                <span v-show="scope.row.Type == 1">期刊论文</span>-->
<!--              </template>-->
            </el-table-column>
            <el-table-column
              label="上传时间"
              prop="CreateDate"
              align="center"
              sortable
              :formatter="formatter"
             width="150">
            </el-table-column>
            <el-table-column
              prop="ArchivesUser"
              label="作者"
              align="center"
              sortable
              width="150">
            </el-table-column>
            <el-table-column
              prop="SerialNumber"
              label="课题/子课题序号"
              align="center"
              sortable
              min-width="170">
            </el-table-column>
            <el-table-column
              prop="HospitalName"
              label="所属单位"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="ArchivesCode"
              label="检索号"
              align="center"
              sortable
              min-width="120px"
              >
            </el-table-column>
            <el-table-column
              prop="JournalArticle"
              label="期刊/论文集名称"
              align="center"
              sortable
              min-width="180px"
            >
            </el-table-column>
            <el-table-column
              prop="Scope"
              label="范围"
              align="center"
              sortable
              >
            </el-table-column>
            <el-table-column
              prop="Factor"
              label="期刊影响因子"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="Searching"
              label="检索源"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="Category"
              label="学科门类"
              align="center"
              sortable
              min-width="170">
            </el-table-column>
            <el-table-column
              prop="Source"
              label="所标注项目/基金号"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="Address"
              label="会议地点"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column
              prop="Remarks"
              label="备注"
              align="center"
              sortable
              min-width="200">
            </el-table-column>
            <el-table-column label="操作" align="center" width="220" fixed="right">
              <template slot-scope="scope">
                <el-link
                  style="margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click.stop="edit(scope.row)"
                ><i class="el-icon-edit-outline"></i>编辑</el-link>
                <el-link
                  style="margin-right: 11px"
                  type="text"
                  :underline="false"
                  @click.stop="delFinance(scope.row)"
                ><i class="el-icon-delete"></i>删除</el-link>
                <el-link
                  type="text"
                  :underline="false"
                  @click="showFile(scope.row)"
                ><img src="../../../assets/image/notice/query.png" alt="" style="width: 16px;height: 16px;vertical-align: -3px">查看附件</el-link>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="eachpage"
          layout="total,sizes,prev, pager, next, jumper"
          @size-change="handleSizeChange"
            :page-sizes="[10, 20, 30, 40,50,60,70,80,90,100]"
          :total="PageCount">
        </el-pagination>
      </div>
    </div>
    <!--      </div>-->
    <div class="voucher-upload">
      <el-dialog
        top="4vh"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">上传论文</span>
        </div>
        <el-form label-width="170px">
          <el-form-item label="论文类型:">
            <el-radio v-model="radio" label="1" >期刊论文</el-radio>
            <el-radio v-model="radio" label="2">会议论文</el-radio>
          </el-form-item>
        </el-form>
        <journal-papers v-if="radio == 1" @paper-child="paperClick" :key="dialogVisible" ></journal-papers>
        <conference-papers v-if="radio == 2" @paper-child="paperClick" :key="dialogVisible" ></conference-papers>
      </el-dialog>
    </div>
    <div class="voucher-upload">
      <el-dialog
        :visible.sync="dialogEdit"
        top="4vh"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">编辑</span>
        </div>
        <edit-journal :Type="1" :paperId="editId" v-if="paperType == 1 && editSeeType ==1" @paper-child="paperEditClick" :key="editId"></edit-journal>
        <edit-conferece :Type="1" :paperId="editId" v-if="paperType ==2 && editSeeType ==1" @paper-child="paperEditClick" :key="editId"></edit-conferece>
      </el-dialog>
    </div>
    <div class="voucher-upload">
      <el-dialog
        :visible.sync="dialogSee"
        top="4vh"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">查看</span>
        </div>
        <edit-journal :Type="2" :paperId="editId" v-if="paperType == 1&&  editSeeType ==2" :key="editId"></edit-journal>
        <edit-conferece :Type="2" :paperId="editId" v-if="paperType == 2 && editSeeType ==2" :key="editId"></edit-conferece>
      </el-dialog>
    </div>
    <div class="commonVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFilesDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该选择的文件，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFilesDialog = false">取消</button>
          <button @click="submitDel">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="commonVoucher-dialog">
      <el-dialog
        top="35vh"
        :visible.sync="delFinanceDialog"
        width="510px"
        v-dialogDrag
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">提示</span>
        </div>
        <div style="text-align: center">
          <i class="el-icon-warning" style="color: #FFBA00;font-size: 24px ;margin-right: 5px"></i>
          <span style="font-size: 18px;color: #666">此操作将永久删除该论文，是否继续？</span>
        </div>
        <div class="btn">
          <button  @click="delFinanceDialog = false">取消</button>
          <button @click="sumDelFinance">确定</button>
        </div>
      </el-dialog>
    </div>
    <div class="dialog-file">
      <el-dialog
        top="12vh"
        :visible.sync="dialogFile"
        width="800px"
        v-dialogDrag
        v-if="dialogFile"
      >
        <div slot="title" class="header-title">
          <span class="title-name"></span>
          <span class="title-age">附件列表</span>
        </div>
        <file-list :DataGuid="FileDataGuid"></file-list>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import FileList from "@/components/Management/FileList";
import reseacrh from "../../../api/research.js"
import {mapState} from "vuex";
import treedata from "@/components/treedata";
import {downloadA, downloadFileId,downLoadXls} from "@/utils/downloadUrl";
import JournalPapers from "@/components/Paper/JournalPapers";
import ConferencePapers from "@/components/Paper/ConferencePapers";
import EditJournal from "@/components/Paper/EditJournal";
import EditConferece from "@/components/Paper/EditConferece";
import {GetPagedManagement, delManagement,exportTableManagement} from "@/api/fileManagement";

export default {
  data(){
    return{
      FileDataGuid: [],
      showFileList: [], // 查看图片的列表
      dialogFile: false, // 查看附件弹框
      loadingExport: false,
      exportData: "ArchivesName,TypeName,CreateDate,ArchivesUser,SerialNumber,HospitalName,ArchivesCode,JournalArticle,Scope," +
        "Factor,Searching,Category,Source,Address,Remarks",
      exportDataName: "论文名称,论文类型,上传时间,作者,课题/子课题序号,所属单位,检索号,期刊/论文集名称,范围,期刊影响因子,检索源,学科门类,所标注项目/基金号,会议地点,备注",
      Type: [1,2],
      optionValue: "",
      option: [
        {
          value: "",
          label: "全部论文"
        },
        {
          value: "1",
          label: "期刊论文"
        },
        {
          value: "2",
          label: "会议论文"
        },
      ],
      SerialNumber: "",
      HospitalName: "",
      editSeeType: "",
      time: "1", // 时间戳
      editId: 0,
      paperType: "",
      dialogSee: false, // 查看弹框
      radio: '1',
      addLoading: false,
      DataGuidList: [], // 下载拼接的数组DataGuid
      api2: window.global_config.BASE_URL1,
      api: window.global_config.BASE_URL1 + "/TopicFiles/",
      imgList: [],
      UserName: window.sessionStorage.getItem('UserName'),
      fileLists: [],//文件列表
      DataGuid: "", // 编辑的DataGuid
      tableDataSee: [], //编辑查看到的数据列表
      delFinanceItem: {}, // 删除凭证的对象
      delFinanceDialog: false, // 删除凭证的弹框
      delFileId: null, // 删除文件的Id
      delFilesDialog: false, // 删除文件的弹框
      dialogEdit: false, // 编辑的弹框
      editForm: { // 编辑对象
      },
      Key: "",
      editFileList:[], // 上传文件列表
      dialogVisible:false, //上传文件弹框
      multipleSelection: [],//多选框数据
      tableData: [],
      imglist:[],
      imgLists: [], // 项目的所有图片列表
      currentPage:1,
      PageCount:0,
      eachpage:10
    }
  },
  computed: mapState(["projectId"]),
  components:{
    treedata,JournalPapers,ConferencePapers,EditJournal,EditConferece,FileList
  },
  created(){
    this.filelist()
  },
  methods:{
    handleSizeChange(val){  //切换每页条数
      this.eachpage = val
      this.filelist()
    },
    getRowKey(row){     //回显
      console.log(row);
      return row.Id;
    },
    paperEditClick(data) { // 编辑弹框的回调取消
      this.dialogEdit = false
      if (data) {
        this.filelist()
      }
    },
    showFile(item) { // 查看附件
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        if(res.data.Status==1){
          this.showFileList=res.data.ExtraData
          if (this.showFileList.length == 0) {
            this.$message.error('您还没有上传附件')
          } else if(this.showFileList.length == 1) {
            this.pre(this.showFileList[0])
          } else {
            this.FileDataGuid = res.data.ExtraData
            this.dialogFile = true
          }
        }else{
          alert(res.data.Message)
        }
      })

    },
    seePaper(item) { // 查看
      this.editId = item.Id
      this.editSeeType = 2 // 防止组件重复刷新数据请求
      if (item.Type== 1) {
        this.paperType = 1
      } else {
        this.paperType = 2
      }
      this.dialogSee = true
    },
    paperClick(data) { //新增弹框的回调取消
      this.dialogVisible = false
      if (data) {
        this.filelist()
      }
    },
    formatter(row,column) { // 时间排序转换格式
      return row.CreateDate.substring(0,10)
    },
    downloadimgList(src,name) { // 已知url
      downloadA(src,name)
    },
    preimgList(Url){ // 项目进来时图片列表的预览
      window.open(this.api2+Url);
    },
    projectIdClick(data) { //树型结构传递过来的课题id
      this.$store.commit('SaveId',data)
      console.log(data,'数的ID')
      this.currentPage = 1;
      this.eachpage = 10;
      this.PageCount = 0
      this.filelist()
    },
    queryList() { // 查询
      this.filelist()
    },
    eidtFileChange(file, fileList) { // 编辑上传文件列表
      this.editFileList = fileList;
    },
    editRemove(file, fileList) { // 编辑上传取消列表
      this.editFileList = fileList;
    },
    add() { // 上传论文文件
      this.dialogVisible=true

    },
    seeFile(item) { // 查看附件
      reseacrh.preFinancFile(item.DataGuid).then(res=>{
        if (res.data.Status==1) {
          res.data.ExtraData.forEach( items => {
            this.downloadfile(items.Id)
          })
        }
      })
    },
    edit(item) { //编辑
      this.DataGuid = item.DataGuid
      this.editId = item.Id
      this.editSeeType = 1
      console.log(item)
      if (item.Type== 1) {
        this.paperType = 1
      } else {
        this.paperType = 2
      }
      this.dialogEdit = true
    },
    handleSelectionChange(val) { //多选
      this.multipleSelection = val;
    },
    handleCurrentChange(val){
      this.currentPage=val
      this.filelist()
    },
    pre(item){
      console.log(item,'pre');
      reseacrh.preview(item.Id).then(res=>{
        console.log(res);
        // if(res.data.Status==1){
        //   let file = window.global_config.BASE_URL1+res.data.ExtraData.Url
        //   if(/\.(pdf|PDF|png|PNG|jpg|JPG|jpeg)$/.test(file)){
        //     window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
        //   }else{
        //     // window.open(window.global_config.BASE_URL1+res.data.ExtraData.Url);
        //     this.$message.warning("暂时只支持pdf文件预览,请下载查看")
        //     this.downloadfile(item.Id)
        //   }
        // }
        this.downloadfile(item.Id,true)
      })
    },
    filelist(){ // 默认期刊
      if (this.optionValue == "") {
        this.Type = [1,2]
      } else {
        this.Type = [...this.optionValue]
      }
      var fileobj={
        TopicId:this.projectId,
        Key: this.Key,
        Type: this.Type,
        SerialNumber: this.SerialNumber,
        HospitalName: this.HospitalName,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
      }
      GetPagedManagement(fileobj).then(res=>{
        if(res.data.Status==1){
          // this.imglist=res.data.Entity
          this.tableData = res.data.Entity
          this.PageCount=res.data.TotalRowsCount
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    submitDel() { // 提交删除单个文件
      reseacrh.alldelet({Id:this.delFileId}).then(res=>{
        if(res.data.Status==1){
          reseacrh.preFinancFile(this.DataGuid).then(res=>{
            if (res.data.Status==1) {
              this.tableDataSee = res.data.ExtraData
              this.delFilesDialog = false
            }
          })
          this.$message.success(res.data.Message)
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    sumDelFinance() { // 确定提交删除
      delManagement({Id:this.delFinanceItem.Id}).then(res=>{
        // console.log(res)
        if(res.data.Status==1){
          this.$message.success(res.data.Message)
          this.delFinanceDialog = false
          this.filelist()
        }else{
          this.$message.error(res.data.Message)
        }
      })
    },
    delFinance (item) { // 删除论文弹框
      this.delFinanceDialog = true
      this.delFinanceItem = item
    },
    deletefile(id){//删除文件
      this.delFileId = id
      this.delFilesDialog = true
    },
    downFiles() { // 凭证下载导出图片
      this.DataGuidList = []
      if (this.multipleSelection.length === 0) {
        this.$message.error('请选择要下载的文件')
        return  false
      }
      console.log(this.multipleSelection)
      this.multipleSelection.forEach(item => {
        this.DataGuidList.push(item.DataGuid)
      })
      reseacrh.FinancZFileZip({DataGuids: this.DataGuidList}).then(res => {
        console.log(res,'拼接图片')
        if (res.status == 200) {
          let data = res.data;
          // let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,"论文.zip")
        } else {
          this.$message.error("文件不存在")
        }
      })
    },
    downloadfile(id,preview = false){
      downloadFileId(id,preview)
    },
    exportFiles() {
      if (this.optionValue == "") {
        this.Type = [1,2]
      } else {
        this.Type = [...this.optionValue]
      }
      var exportobj={
        TopicId:this.projectId,
        Key: this.Key,
        Type: this.Type,
        SerialNumber: this.SerialNumber,
        HospitalName: this.HospitalName,
        PageIndex:this.currentPage-1,
        PageSize:this.eachpage,
        titleName: this.exportDataName,
        titleField: this.exportData,
        ExportFileName: "论文"

      }
      this.loadingExport = true
      exportTableManagement(exportobj).then(res=> {
        if (res.status == 200) {
          this.loadingExport = false
          let data = res.data;
          let filename = decodeURIComponent(res.headers.filename)
          downLoadXls(data,filename)
        } else {
          this.loadingExport = false
          this.$message.error("导出失败")
        }
      }).catch((error)=> {
        console.log(error)
        this.loadingExport = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../styles/file_list.scss";
.voucher-upload {
  /deep/ .el-dialog {
    width: 1222px!important;
  }
}
</style>
