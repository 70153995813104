<template>
  <div class="utility-model-page">
    <el-form ref="editForm" label-width="170px" :rules="rules" :model="editForm">
      <el-row type="flex" class="row-bg" justify="end">
        <el-col :span="11">
          <el-form-item label="论文类型:">
            <el-radio v-model="radio2" label="1" >会议论文</el-radio>
          </el-form-item>
          <el-form-item label="论文名称:" prop="ArchivesName">
            <el-input v-model="editForm.ArchivesName"></el-input>
          </el-form-item>
          <el-form-item label="会议日期:" prop="IssuingDate">
            <el-date-picker
              v-model="editForm.IssuingDate"
              type="month"
              placeholder="请选入年份+月份"
              format="yyyy 年 MM 月"
              value-format="yyyy-MM">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="入选论文集名称:" prop="JournalArticle">
            <el-input  v-model="editForm.JournalArticle"></el-input>
          </el-form-item>
          <el-form-item label="作者(1-6):" prop="ArchivesUser">
            <el-input v-model="editForm.ArchivesUser"></el-input>
          </el-form-item>
          <el-form-item label="范围:">
            <el-radio v-model="editForm.Scope" label="全国">全国</el-radio>
            <el-radio v-model="editForm.Scope" label="国际">国际</el-radio>
            <el-radio v-model="editForm.Scope" label="地方">地方</el-radio>
          </el-form-item>
        </el-col>
<!--        <el-col :span="1">-->
<!--        </el-col>-->
        <el-col :span="12">
          <el-form-item label="检索源:" prop="Searching">
            <!--        <el-input v-model="addForm.Searching"></el-input>-->
            <el-select v-model="editForm.Searching" placeholder="请选择">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
                <span style="float: left">{{ item.label }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="学科门类:" prop="Category">
            <el-input v-model="editForm.Category"></el-input>
          </el-form-item>
          <el-form-item label="所标注项目/基金号:" prop="Source">
            <el-input v-model="editForm.Source"></el-input>
          </el-form-item>
          <el-form-item label="会议地点:" prop="Address">
            <el-input v-model="editForm.Address"></el-input>
          </el-form-item>
          <el-form-item label="附件:">
            <div style="display:flex;flex-wrap: wrap;">
              <management-list :DataGuid="editForm.DataGuid" :key="editForm.Id + keyId" :Type="Type"></management-list>
              <div v-if="Type == 1">
                <el-upload
                  class="upload-demo"
                  ref="newupload"
                  accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"
                  multiple
                  action="#"
                  :file-list="fileList"
                  :auto-upload="false"
                  :on-change="handleFileChange"
                  :before-remove="handleRemove"
                >
                  <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>
                </el-upload>
              </div>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="1">
        </el-col>
      </el-row>
<!--      <el-form-item label="论文类型:">-->
<!--        <el-radio v-model="radio2" label="1" >会议论文</el-radio>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="论文名称:" prop="ArchivesName">-->
<!--        <el-input v-model="editForm.ArchivesName"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="会议日期:" prop="IssuingDate">-->
<!--        <el-date-picker-->
<!--          v-model="editForm.IssuingDate"-->
<!--          type="month"-->
<!--          placeholder="请选入年份+月份"-->
<!--          format="yyyy 年 MM 月"-->
<!--          value-format="yyyy-MM">-->
<!--        </el-date-picker>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="入选论文集名称:" prop="JournalArticle">-->
<!--        <el-input  v-model="editForm.JournalArticle"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="作者(1-6):" prop="ArchivesUser">-->
<!--        <el-input v-model="editForm.ArchivesUser"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="范围:">-->
<!--        <el-radio v-model="editForm.Scope" label="全国">全国</el-radio>-->
<!--        <el-radio v-model="editForm.Scope" label="国际">国际</el-radio>-->
<!--        <el-radio v-model="editForm.Scope" label="地方">地方</el-radio>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="检索源:" prop="Searching">-->
<!--        &lt;!&ndash;        <el-input v-model="addForm.Searching"></el-input>&ndash;&gt;-->
<!--        <el-select v-model="editForm.Searching" placeholder="请选择">-->
<!--          <el-option-->
<!--            v-for="item in options"-->
<!--            :key="item.value"-->
<!--            :label="item.label"-->
<!--            :value="item.value"-->
<!--          >-->
<!--            <span style="float: left">{{ item.label }}</span>-->
<!--          </el-option>-->
<!--        </el-select>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="学科门类:" prop="Category">-->
<!--        <el-input v-model="editForm.Category"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="所标注项目/基金号:" prop="Source">-->
<!--        <el-input v-model="editForm.Source"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="会议地点:" prop="Address">-->
<!--        <el-input v-model="editForm.Address"></el-input>-->
<!--      </el-form-item>-->
<!--      <el-form-item label="附件:">-->
<!--        <div style="display:flex;flex-wrap: wrap;">-->
<!--          <management-list :DataGuid="editForm.DataGuid" :key="editForm.Id + keyId" :Type="Type"></management-list>-->
<!--          <div v-if="Type == 1">-->
<!--            <el-upload-->
<!--              class="upload-demo"-->
<!--              ref="newupload"-->
<!--              accept=".xlsx,.xls,.jpg,.mp4,.mp3,.png,.txt,.svg,.pdf,.docx,.rar,.ppt,.doc,.zip"-->
<!--              multiple-->
<!--              action="#"-->
<!--              :file-list="fileList"-->
<!--              :auto-upload="false"-->
<!--              :on-change="handleFileChange"-->
<!--              :before-remove="handleRemove"-->
<!--            >-->
<!--              <el-button size="medium" plain icon="el-icon-top" style="color: #4d90f1;border: 1px solid #4d90f1">上传文件</el-button>-->
<!--            </el-upload>-->
<!--          </div>-->
<!--        </div>-->
<!--      </el-form-item>-->
    </el-form>
    <div class="btn1" v-show="Type == 1">
      <el-button  @click="cancel('editForm')">取消</el-button>
      <el-button  @click="onSumbit('editForm')" :loading="loading">确定</el-button>
    </div>
  </div>
</template>

<script>
import {ruleData} from "@/utils/validationRules"
import {editManagement, infoManagement} from "@/api/fileManagement";

export default {
  data() {
    return {
      keyId: "",
      radio2: "1",
      radio: "1",
      loading: false,
      fileList: [],
      editForm: {
      },
      ResetForm: {}, // 取消重置编辑的数据，防止再次点击相同id调接口
      rules: ruleData,
      options: [
        {
          value: "EI来源期刊",
          label: "EI来源期刊"
        },
        {
          value: "SCI来源期刊",
          label: "SCI来源期刊"
        },
        {
          value: "核心期刊",
          label: "核心期刊"
        },
        {
          value: "统计源期刊",
          label: "统计源期刊"
        },
        {
          value: "其他",
          label: "其他"
        },
      ],
    }
  },
  props: ["Type","paperId"],
  created() {
    this.getInfo()
  },
  methods: {
    getInfo() {
      infoManagement({Id: this.paperId}).then(res => {
        if (res.data.Status == 1) {
          console.log(res,"编辑数据")
          this.editForm = res.data.ExtraData
          this.ResetForm = JSON.parse(JSON.stringify(this.editForm))  // 防止赋值同时改变
        }
      })
    },
    handleRemove(file, fileList) { // 文件选择之后取消
      this.fileList = fileList;
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    cancel(formName) { // 取消
      // this.$refs[formName].resetFields();
      // this.editForm = JSON.parse(JSON.stringify(this.ResetForm))
      this.editForm = this.ResetForm
      this.$emit("paper-child",false)
    },
    onSumbit(formName) { //上传提交
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let fd = new FormData();
          this.fileList.forEach(file => {
            fd.append('file',file.raw)
          })
          fd.append("data", JSON.stringify(this.editForm));
          editManagement(fd).then((res) => {
            if (res.data.Status == 1) {
              // this.$refs[formName].resetFields();
              if (this.fileList.length> 0) {
                this.keyId++
              }
              this.fileList = []
              this.$emit("paper-child",true)
              this.$message.success(res.data.Message);
            }else{
              this.editForm = this.ResetForm
              this.$message.error(res.data.Message)
            }
          });
        } else {
          this.$message.error('注意必填项');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">

</style>
